<template>
    <div class="mb-4 ins_box">
        <div class="text-gray-400 title text-left duration-500 pc_show">收货地址</div>
        <div>
            <div class="mt-8 flex sm:justify-center equipment duration-500">
                <el-form ref="form" label-width="90px" label-position="left">
                    <el-form-item label="收货人" class="flex justify-start">
                        <el-input
                            v-model="contactName"
                            placeholder="请填写收货人姓名"
                            class="form_input duration-500"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="手机号码" class="flex justify-start">
                        <el-input
                            v-model="phone"
                            placeholder="请填写手机号码"
                            class="form_input duration-500"
                        ></el-input>
                    </el-form-item>

                    <el-form-item label="地址" class="flex justify-start">
                        <div class="pc_select_show">
                            <el-cascader
                                size="large"
                                :options="options"
                                v-model="selectedOptions"
                                @change="handleChange"
                                class="form_input"
                            ></el-cascader>
                        </div>
                        <div class="phone_select_show">
                            <div class="form_input custom_input flex" @click="drawer = true">
                                <span
                                    :class="address ? '' : 'text-gray-300'"
                                >{{ address == '' ? '请选择地址' : address }}</span>
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item label="详细地址" class="flex justify-start">
                        <el-input
                            v-model="detailAddress"
                            placeholder="请填写详细地址"
                            class="form_input duration-500"
                        ></el-input>
                    </el-form-item>

                    <el-form-item label="." class="flex justify-start btn_item">
                        <div
                            class="text-sm flex items-center cursor-pointer"
                            @click="acquiescence = !acquiescence"
                        >
                            <span
                                style="height:.875rem;width: .875rem;border:.0625rem solid #cccc;box-sizing: border-box; "
                                class="rounded mr-1 flex justify-center items-center"
                            >
                                <span
                                    style="height:.5rem;width: .5rem;display: inline-block;border-radius: .125rem;"
                                    class="bg-primary"
                                    v-if="acquiescence"
                                ></span>
                            </span>

                            <span class="text-gray-400" style="user-select:none">设为默认地址</span>
                        </div>
                    </el-form-item>
                    <el-form-item label="." class="flex justify-start btn_item">
                        <el-button type="primary" @click="checkingRule" class="btn duration-500">确定</el-button>
                        <!-- <el-button style="width:200px">解绑</el-button> -->
                    </el-form-item>
                </el-form>
            </div>
            <div class="table w-full">
                <div class="table_title duration-500 flex items-center w-full">
                    <div
                        v-for="(item,index) in tableTitle"
                        :key="index"
                        class="h-full flex items-center duration-500"
                        style="width:25%"
                        :style="{ width: index == 0 ? '20%' : '26.6666%' }"
                        :class="index == 0 ? 'justify-start' : index == 3 ? 'justify-end' : 'justify-center'"
                    >{{ item }}</div>
                </div>
                <ul class="w-full" v-if="getAddressList.length > 0">
                    <li
                        class="flex w-full duration-500 items-center"
                        v-for="item in getAddressList"
                        :key="item.receiptId"
                    >
                        <div
                            class="flex justify-start items-center ma_li_item duration-500"
                            style="width: 20%;"
                            @click="goDetail(item)"
                        >{{ item.contactName }}</div>
                        <div
                            class="flex justify-center items-center ma_li_item duration-500"
                            style="width: 26.66%;"
                        >{{ item.detailAddress }}</div>
                        <div
                            class="flex justify-center items-center ma_li_item duration-500"
                            style="width: 26.66%;"
                        >{{ item.phone }}</div>
                        <div
                            class="flex justify-end items-center ma_li_item duration-500"
                            style="width: 26.66%;"
                        >
                            <span class="text-primary cursor-pointer" @click="deleteInfo(item)">删除</span>
                        </div>
                    </li>
                </ul>
                <div
                    v-else
                    class="flex justify-center items-center text-gray-500 null_box py-3"
                >未添加收货地址</div>
            </div>
        </div>
        <el-drawer
            title
            :visible.sync="drawer"
            :direction="direction"
            :before-close="handleClose"
            :show-close="false"
            :withHeader="false"
            size="50%"
        >
            <div style="height: 50vh;width: 100vw;">
                <v-distpicker type="mobile" @selected="selected"></v-distpicker>
            </div>
        </el-drawer>
    </div>
</template>

<script>
import { regionData } from 'element-china-area-data'
import { mapGetters } from 'vuex';
export default {
    data() {
        return {
            tableTitle: ['收货人', '收货地址', '手机号', '操作'],
            addressList: [], // 收货地址列表
            contactName: "",
            phone: "",
            detailAddress: "", //详细地址
            acquiescence: false, // 是否默认地址
            options: regionData,
            selectedOptions: [],
            drawer: false,
            direction: 'btt',
            //省市区
            address: "",
            addressCode: [],
        };
    },
    created() {
        // this.getAddress()
        this.$store.dispatch("aquireAddress")
    },
    computed: {
        ...mapGetters(['getAddressList'])
    },
    methods: {
        changeTab(index) {
            this.tabIndex = index
        },
        // 获取收货地址
        // getAddress() {
        //     this.$request({
        //         method: "get",
        //         url: this.$requestPath.getAddress,
        //         data: {}
        //     }).then(res => {
        //         if (res.code == 0) {
        //             res.data.forEach(item => {
        //                 item.codeToAddress = ""
        //                 if (item.cityCode) {
        //                     item.codeToAddress = this.getCodeToText(item.cityCode)
        //                 }
        //                 item.detailAddress = item.codeToAddress + item.address
        //             })
        //             this.addressList = []
        //             this.addressList.push(...res.data)
        //         } else {
        //             this.$EventBus.$emit("toast", { type: 'error', message: '获取收货地址失败' })
        //         }
        //     }).catch(error => {
        //         console.log("获取收货地址error", error)
        //     })
        // },
        // 校验
        checkingRule() {
            if (this.isNull(this.contactName)) {
                this.$EventBus.$emit("toast", { type: 'error', message: '用户名不能为空' })
                return
            }
            if (!this.checkPhone(this.phone)) {
                this.$EventBus.$emit("toast", { type: 'error', message: '手机号码错误' })
                return
            }
            if (this.addressCode.length == 0) {
                this.$EventBus.$emit("toast", { type: 'error', message: '请选择地址' })
                return
            }
            if (this.isNull(this.detailAddress)) {
                this.$EventBus.$emit("toast", { type: 'error', message: '请输入详细地址' })
                return
            }

            this.onSubmit()
        },
        onSubmit() {
            this.$request({
                method: "post",
                url: this.$requestPath.addAddress,
                data: {
                    acquiescence: this.acquiescence ? 1 : 0,
                    address: this.detailAddress,
                    cityCode: this.addressCode.join(","),
                    contactName: this.contactName,
                    phone: this.phone
                }
            }).then(res => {
                if (res.code == 0) {
                    this.$EventBus.$emit("toast", { type: 'success', message: '地址添加成功' })
                    this.acquiescence = 0
                    this.detailAddress = ""
                    this.phone = ""
                    this.contactName = ""
                    this.addressCode = []
                    this.selectedOptions = []
                    this.address = ""
                    this.$store.dispatch("aquireAddress")
                } else {
                    this.$EventBus.$emit("toast", { type: 'error', message: '地址添加失败，请稍后再试' })
                }
            }).catch(error => {
                console.log("添加收货地址error", error)
            })
        },
        handleChange(value) {
            this.addressCode = []
            this.addressCode.push(...value)
        },
        handleClose(done) {
            done();
        },
        selected(e) {
            console.log(e)
            this.address = e.province.value + "/" + e.city.value + "/" + e.area.value
            this.addressCode = []
            this.addressCode = [e.province.code, e.city.code, e.area.code]
            this.drawer = false
        },
        deleteInfo(item) {
            let isMobile = localStorage.getItem("isMobile")
            if (isMobile == "true") {
                this.$confirm('确定删除该地址吗？', options).then(() => {
                    this.deleteAddress(item)
                }).catch(() => {
                    console.log("点击了关闭")
                })
                let options = {
                    title: '', // 默认无标题
                    yes: {
                        text: '确定',
                        style: {
                            color: '#DD1821'
                        }
                    },
                    no: {
                        text: '取消',
                        style: {
                            color: '#DD1821'
                        }
                    }
                }
            } else {
                this.$eleconfirm('确定删除该地址吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.deleteAddress(item)
                }).catch(() => { });
            }

        },
        // 删除地址
        deleteAddress(item) {
            this.$request({
                method: "delete",
                url: this.$requestPath.deleteAddress,
                data: {
                    receiptId: item.receiptId
                }
            }).then(res => {
                if (res.code == 0) {
                    this.$EventBus.$emit("toast", { type: 'success', message: '地址删除成功' })
                    this.$store.dispatch("aquireAddress")
                } else {
                    this.$EventBus.$emit("toast", { type: 'error', message: '地址删除失败，请稍后再试' })
                }
            }).catch(error => {
                console.log("删除收货地址失败", error)
            })
        },
        // 地址转换
        // getCodeToText(codeStr) {
        //     let codeArray = codeStr.split(',')
        //     let area = "";
        //     switch (codeArray.length) {
        //         case 1:
        //             area += CodeToText[codeArray[0]];
        //             break;
        //         case 2:
        //             area += CodeToText[codeArray[0]] + CodeToText[codeArray[1]];
        //             break;
        //         case 3:
        //             area +=
        //                 CodeToText[codeArray[0]] + CodeToText[codeArray[1]] + CodeToText[codeArray[2]];
        //             break;
        //         default:
        //             break;
        //     }
        //     return area;
        // }

    },
};
</script>

<style lang='scss' scoped>
.border_box {
    border: 0.0625rem solid var(--border-base);
}

.table_title {
    background: #e8e8e8;
}
.li_item {
    width: 33.33%;
}

@media screen and (max-width: 640px) {
    .form_input {
        width: 13.75rem;
    }
    .btn {
        width: 6.25rem;
    }
    .table {
        margin-top: 0.625rem;
        .table_title {
            height: 2.1875rem;
            font-size: 0.8125rem;
            padding: 0px 0.625rem;
        }
        ul {
            li {
                padding: 0.375rem 0.625rem;
                font-size: 0.75rem;
            }

            li {
                border: 0.0625rem solid #ccc;
                border-top: none;
            }
            li:nth-child(1) {
                border-top: 0.0625rem solid #ccc;
            }
        }
    }
}
@media screen and (max-width: 900px) {
    .pc_show {
        display: none;
    }
    .title {
        font-size: 1.25rem;
    }
}
@media screen and (min-width: 640px) {
    .form_input {
        width: 18.75rem;
    }
    .btn {
        width: 12.5rem;
    }
    .table {
        margin-top: 0.625rem;
        .table_title {
            height: 2.5rem;
            padding: 0px 0.625rem;
        }
        ul {
            li {
                padding: 1.25rem 0.625rem;
                font-size: 0.875rem;
            }

            li {
                border: 0.0625rem solid #ccc;
                border-top: none;
            }
            li:nth-child(1) {
                border-top: 0.0625rem solid #ccc;
            }
        }
    }
}
@media screen and (min-width: 900px) {
    .pc_show {
        display: block;
    }
    .title {
        font-size: 1.875rem;
    }
}
</style>
<style>
.btn_item label {
    opacity: 0;
}
.equipment .el-form-item__content {
    margin-left: 0.625rem !important;
}
.address-container {
    overflow: scroll;
    height: calc(50vh - 2.5625rem);
}
</style>